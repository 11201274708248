import { FormattedMessage } from "react-intl";
import { Navigate, Route, Routes } from "react-router-dom";

import { SidebarSectionHeader, SidebarV2 } from "common/sidebar";
import { useHeadingDetails } from "common/settingsv2/common";
import { usePermissions } from "common/core/current_user_role";
import { Feature } from "graphql_globals";

import {
  OrganizationSettings,
  SettingsSidebarOrgLink,
  ORG_ROUTE,
} from "../sidebar_settings/organization";
import {
  OrganizationOverview,
  OrganizationOverviewTab,
  ORG_OVERVIEW_ROUTE,
} from "../sidebar_settings/organization/overview";
import {
  BillingSettings,
  SettingsSidebarBillingLink,
  BILLING_ROUTE,
} from "../sidebar_settings/billing";
import {
  BILLING_PLAN_DETAILS_ROUTE,
  PlanDetails,
  PlanDetailsTab,
} from "../sidebar_settings/billing/plan_details";
import {
  PaymentSettings,
  PaymentSettingsTab,
  BILLING_PAYMENT_SETTINGS_ROUTE,
} from "../sidebar_settings/billing/payment_settings";
import {
  BillingContact,
  BillingContactTab,
  BILLING_BILLING_CONTACT_ROUTE,
} from "../sidebar_settings/billing/billing_contact";
import {
  RecentPayments,
  RecentPaymentsTab,
  BILLING_RECENT_PAYMENTS_ROUTE,
} from "../sidebar_settings/billing/recent_payments";
import {
  TransactionSettings,
  SettingsSidebarTransactionsLink,
  TRANSACTIONS_ROUTE,
} from "../sidebar_settings/transaction";
import {
  SecuritySettings,
  SettingsSidebarSecurityLink,
  SECURITY_ROUTE,
} from "../sidebar_settings/security";
import LoginSecurity from "../sidebar_settings/security/login_security";
import {
  SignerSettings,
  SignerSettingsTab,
  TRANSACTIONS_SIGNER_SETTINGS_ROUTE,
} from "../sidebar_settings/transaction/signer_settings";
import {
  NotaryInstructions,
  NotaryInstructionsTab,
  TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE,
} from "../sidebar_settings/transaction/notary_instructions";
import { TermsSettings, TERMS_ROUTE } from "../sidebar_settings/terms";
import { TermsAndConditions } from "../sidebar_settings/terms/terms";
import {
  ClosingSettings,
  ClosingSettingsTab,
  TRANSACTIONS_CLOSING_SETTINGS_ROUTE,
} from "../sidebar_settings/transaction/closing_settings";
import {
  BrandSettings as BrandSettingsWrapper,
  SettingsSidebarBrandCustomizationLink,
  BRAND_SETTINGS_ROOT_PREFIX,
} from "../sidebar_settings/brand";
import { BrandSettings, BRAND_SETTINGS_MAIN_ROUTE } from "../sidebar_settings/brand/settings";
import {
  LogoAndHeaderTab,
  LogoAndHeaderBrandSettings,
  LOGO_HEADER_BRAND_ROUTE,
} from "../sidebar_settings/brand/settings/logo_and_header";
import {
  BrandColorsTab,
  BrandColorsSettings,
  BRAND_COLORS_ROUTE,
} from "../sidebar_settings/brand/settings/brand_colors";
import {
  IntegrationsSettings as IntegrationSettingsContainer,
  INTEGRATIONS_ROUTE,
  SettingsSidebarIntegrationsLink,
} from "../sidebar_settings/integrations";
import IntegrationsSettings from "../sidebar_settings/integrations/settings";
import {
  API_KEYS_SETTINGS_ROOT_PREFIX,
  SettingsSidebarApiKeysLink,
  ApiKeys,
} from "../sidebar_settings/api_keys";
import { CollabFeeSetting } from "./collab_fee_setting";
import {
  CustomizeLandingPage,
  BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE,
  CustomizeLandingPageTab,
} from "../sidebar_settings/brand/customization/landing_page";

function LenderAdminSidebar() {
  const { orgName, featureList } = useHeadingDetails();
  const { hasPermissionFor } = usePermissions();
  return (
    <SidebarV2
      title={
        <FormattedMessage id="194b6c1a-c36c-4f13-bdf6-56188f74c163" defaultMessage="Settings" />
      }
    >
      <SidebarSectionHeader title={orgName} />
      <SettingsSidebarOrgLink />
      <SettingsSidebarBillingLink />
      <SettingsSidebarTransactionsLink />
      <SettingsSidebarSecurityLink />
      <SettingsSidebarBrandCustomizationLink />
      <SettingsSidebarIntegrationsLink />
      {featureList.includes(Feature.PARTNER_API) && hasPermissionFor("viewOrganizationApiKeys") && (
        <SettingsSidebarApiKeysLink />
      )}
    </SidebarV2>
  );
}

export default function LenderAdminSettings() {
  const { lenderTitleFeeCollabEnabled } = useHeadingDetails();
  const extendedSettings = lenderTitleFeeCollabEnabled ? <CollabFeeSetting /> : null;
  return (
    <>
      <LenderAdminSidebar />
      <Routes>
        <Route
          path={ORG_ROUTE}
          element={<OrganizationSettings tabs={<OrganizationOverviewTab />} />}
        >
          <Route path={ORG_OVERVIEW_ROUTE} element={<OrganizationOverview />} />
          <Route index element={<Navigate to={ORG_OVERVIEW_ROUTE} replace />} />
        </Route>

        <Route
          path={BILLING_ROUTE}
          element={
            <BillingSettings
              tabs={
                <>
                  <PlanDetailsTab />
                  <PaymentSettingsTab />
                  <BillingContactTab />
                  <RecentPaymentsTab />
                </>
              }
            />
          }
        >
          <Route path={BILLING_PLAN_DETAILS_ROUTE} element={<PlanDetails />} />
          <Route
            path={BILLING_PAYMENT_SETTINGS_ROUTE}
            element={<PaymentSettings extendedSettings={extendedSettings} />}
          />
          <Route path={BILLING_BILLING_CONTACT_ROUTE} element={<BillingContact />} />
          <Route path={BILLING_RECENT_PAYMENTS_ROUTE} element={<RecentPayments />} />
          <Route index element={<Navigate to={BILLING_PLAN_DETAILS_ROUTE} replace />} />
        </Route>

        <Route
          path={TRANSACTIONS_ROUTE}
          element={
            <TransactionSettings
              tabs={
                <>
                  <SignerSettingsTab />
                  <NotaryInstructionsTab />
                  <ClosingSettingsTab />
                </>
              }
            />
          }
        >
          <Route path={TRANSACTIONS_SIGNER_SETTINGS_ROUTE} element={<SignerSettings />} />
          <Route path={TRANSACTIONS_NOTARY_INSTRUCTIONS_ROUTE} element={<NotaryInstructions />} />
          <Route path={TRANSACTIONS_CLOSING_SETTINGS_ROUTE} element={<ClosingSettings />} />
          <Route index element={<Navigate to={TRANSACTIONS_SIGNER_SETTINGS_ROUTE} replace />} />
        </Route>

        <Route path={SECURITY_ROUTE} element={<SecuritySettings />}>
          <Route index element={<LoginSecurity />} />
        </Route>

        <Route path={BRAND_SETTINGS_ROOT_PREFIX} element={<BrandSettingsWrapper />}>
          <Route
            path={BRAND_SETTINGS_MAIN_ROUTE}
            element={
              <BrandSettings
                tabs={
                  <>
                    <LogoAndHeaderTab />
                    <BrandColorsTab />
                    <CustomizeLandingPageTab />
                  </>
                }
              />
            }
          >
            <Route path={LOGO_HEADER_BRAND_ROUTE} element={<LogoAndHeaderBrandSettings />} />
            <Route path={BRAND_COLORS_ROUTE} element={<BrandColorsSettings />} />
            <Route
              path={BRAND_SETTINGS_CUSTOMIZE_LANDING_ROUTE}
              element={<CustomizeLandingPage />}
            />
            <Route index element={<Navigate to={LOGO_HEADER_BRAND_ROUTE} replace />} />
          </Route>
          <Route index element={<Navigate replace to={BRAND_SETTINGS_MAIN_ROUTE} />} />
        </Route>

        <Route path={INTEGRATIONS_ROUTE} element={<IntegrationSettingsContainer />}>
          <Route index element={<IntegrationsSettings />} />
        </Route>
        <Route path={API_KEYS_SETTINGS_ROOT_PREFIX} element={<ApiKeys showApi />} />

        <Route path={TERMS_ROUTE} element={<TermsSettings />}>
          <Route index element={<TermsAndConditions />} />
        </Route>

        <Route index element={<Navigate replace to={`${ORG_ROUTE}/${ORG_OVERVIEW_ROUTE}`} />} />
      </Routes>
    </>
  );
}
