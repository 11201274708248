import { useRef, useState, type ChangeEvent } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { Feature, NotaryProfileSettingValues } from "graphql_globals";
import { useQuery, useMutation } from "util/graphql";
import { simpleAssetUpload } from "util/uploader";
import { useActiveOrganization } from "common/account/active_organization";
import { Paragraph } from "common/core/typography";
import Tab from "common/core/tabs/tab";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";
import Button from "common/core/button";
import SROnly from "common/core/screen_reader";
import { Card, CardHeading, CardText } from "common/core/card";
import LoadingIndicator from "common/core/loading_indicator";
import { useId } from "util/html";
import { useIsOrganizationBackgroundFlagEnabled } from "common/notary/meeting_background";

import Styles from "./index.module.scss";
import OrganizationNotaryBackgroundSettingsQuery, {
  type OrganizationNotaryBackgroundSettings_organization_Organization as Organization,
} from "./index_query.graphql";
import UpdateOrganizationBackgroundMutation from "./update_organization_background_mutation.graphql";

export const NOTARY_BG_ROUTE = "notary-background";
const MAX_UPLOAD_SIZE_BYTES = 5_242_880; // 5mb
const MESSAGES = defineMessages({
  success: {
    id: "907b2cb0-40fd-4488-8549-e6cafc7dc54b",
    defaultMessage: "Default notary background set",
  },
  tooBig: { id: "8f54da6d-938f-4a4e-9b81-6500744963c5", defaultMessage: "File is too large" },
  failure: {
    id: "f12a30ea-fe15-4ef4-b6f6-46f41e09a20b",
    defaultMessage: "Something went wrong",
  },
  customAlt: {
    id: "2d1d7a06-0105-445a-ab3c-9c3f6e0f8add",
    defaultMessage: "Custom uploaded background preview",
  },
});

export function useIsOrganizationBackgroundSettingsEnabled(orgFeatureList: Feature[]): boolean {
  return (
    useIsOrganizationBackgroundFlagEnabled() &&
    orgFeatureList.includes(Feature.ORGANIZATION_NOTARIES)
  );
}

export function NotaryBackgroundTab() {
  return (
    <Tab to={NOTARY_BG_ROUTE}>
      <FormattedMessage
        id="331bf8dc-c547-4a42-9a34-984cacb45e73"
        defaultMessage="Video backgrounds"
      />
    </Tab>
  );
}

function useUpdateFeedback(intl: ReturnType<typeof useIntl>) {
  const [updatingCustomBg, setUpdatingCustomBg] = useState(false);
  return {
    updatingCustomBg,
    handleUpdate(promise: Promise<unknown>) {
      setUpdatingCustomBg(true);
      return promise
        .then(() => {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.SUCCESS,
            message: intl.formatMessage(MESSAGES.success),
          });
        })
        .catch(() => {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            subtype: NOTIFICATION_SUBTYPES.ERROR,
            message: intl.formatMessage(MESSAGES.failure),
          });
        })
        .finally(() => {
          setUpdatingCustomBg(false);
        });
    },
  };
}

function NotaryBackgroundSettingsInner({ organization }: { organization: Organization }) {
  const intl = useIntl();
  const { updatingCustomBg, handleUpdate } = useUpdateFeedback(intl);
  const uploadInputId = useId();
  const uploadInputDescriptionId = useId();
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const updateOrganizationBackgroundMutateFn = useMutation(UpdateOrganizationBackgroundMutation);

  const { id: organizationId, backgroundSetting } = organization;
  const customUrl =
    backgroundSetting?.__typename === "OrganizationCustomBackgroundUrl"
      ? backgroundSetting.url.url
      : undefined;

  const handleInputUpload = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = target.files?.[0];
    if (!file || updatingCustomBg) {
      return;
    }
    if (file.size > MAX_UPLOAD_SIZE_BYTES) {
      target.value = "";
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.ERROR,
        message: intl.formatMessage(MESSAGES.tooBig),
      });
      return;
    }
    handleUpdate(
      simpleAssetUpload({ asset: file }).then((key) =>
        updateOrganizationBackgroundMutateFn({
          variables: {
            input: { organizationId, backgroundSetting: key },
          },
        }),
      ),
    );
  };

  return (
    <Card className={Styles.card}>
      <CardHeading>
        <FormattedMessage
          id="bf413a93-f660-4064-b113-fb2ef75f6bb2"
          defaultMessage="Notary video backgrounds"
        />
      </CardHeading>
      <CardText>
        <FormattedMessage
          id="bfb0d03f-e4e0-4181-b2f9-f0471be1af0b"
          defaultMessage="Configure the default video background for the notary in all organization meetings. Enhance your brand experience by uploading a custom background image."
        />
      </CardText>

      <label className={Styles.customUploadLabel} htmlFor={uploadInputId}>
        <FormattedMessage
          id="3c04bfae-e6ef-430a-94ea-702b10767337"
          defaultMessage="Upload a custom background"
        />
      </label>
      <Paragraph className={Styles.customUploadDescription} id={uploadInputDescriptionId}>
        <FormattedMessage
          id="e78e7213-c52c-4056-8cdd-2fc2e4b170ed"
          defaultMessage="Accepts .jpeg and .png"
        />
      </Paragraph>

      {customUrl && (
        <img
          src={customUrl}
          className={Styles.customUpload}
          alt={intl.formatMessage(MESSAGES.customAlt)}
        />
      )}

      <Button
        aria-hidden="true"
        buttonColor="action"
        isLoading={updatingCustomBg}
        variant="secondary"
        withIcon={{ placement: "left", name: "arrow-up-square" }}
        onClick={() => {
          uploadInputRef.current?.click();
        }}
        aria-describedby={uploadInputDescriptionId}
      >
        <FormattedMessage
          id="372eb6f2-c902-463c-960e-547712255a9e"
          defaultMessage="{isCustom, select, true{Update} other{Upload}} image"
          values={{ isCustom: backgroundSetting?.__typename === "OrganizationCustomBackgroundUrl" }}
        />
      </Button>
      <SROnly>
        <input
          ref={uploadInputRef}
          id={uploadInputId}
          type="file"
          accept="image/png,image/jpeg"
          onChange={handleInputUpload}
          disabled={updatingCustomBg}
        />
      </SROnly>

      {customUrl && (
        <Button
          buttonColor="danger"
          variant="secondary"
          className={Styles.delete}
          isLoading={updatingCustomBg}
          onClick={() => {
            handleUpdate(
              updateOrganizationBackgroundMutateFn({
                variables: {
                  input: { organizationId, backgroundSetting: NotaryProfileSettingValues.NO_BLUR },
                },
              }),
            );
          }}
        >
          <FormattedMessage id="4f92f169-2b68-4f2b-823d-aa17ca8a4b97" defaultMessage="Delete" />
        </Button>
      )}
    </Card>
  );
}

export function NotaryBackgroundSettings() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(OrganizationNotaryBackgroundSettingsQuery, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data?.organization;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected Organization, got ${organization?.__typename}`);
  }

  return <NotaryBackgroundSettingsInner organization={organization} />;
}
