import "./index.scss";

import { useCallback, useState, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useMatch, useNavigate, useParams } from "react-router-dom";

import Overlay from "common/modals/overlay";
import Button from "common/core/button";
import Link from "common/core/link";
import { BinaryToggle } from "common/core/form/binary_toggle";
import { PDFViewer } from "common/pdf/pspdfkit/viewer";
import Uploader from "common/core/uploader";
import { DeprecatedSelectInput } from "common/form/inputs/select";
import { TemplatePresetDesignationType, AsyncJobStatus } from "graphql_globals";
import { useMutation } from "util/graphql";
import { QueryWithLoading, useLazyQuery } from "util/graphql/query";
import { useId } from "util/html";
import { ToolsRedirect } from "util/routes";
import { ROUTE as TEMPLATES_ROUTE } from "common/tools/document_templates";
import { useMoveTools } from "util/feature_detection";

import TemplateToolsQuery, {
  type TemplateTools_node_OrganizationDocumentTemplate as TemplateToolsNode,
} from "./template_tools_query.graphql";
import CompareFileToTemplateMutation from "./compare_file_to_template_mutation.graphql";
import CompareFielToTemplateResultQuery, {
  type CompareFileToTemplateResult_template_OrganizationDocumentTemplate as CompareFileToTemplateResultTemplate,
  type CompareFileToTemplateResult_template_OrganizationDocumentTemplate_asyncJobs_result_diffResults as DiffResults,
} from "./compare_file_to_template_result_query.graphql";
import UpdateOrganizationDocumentTemplateMutation from "../update_organization_document_template_mutation.graphql";

type Props = {
  template: TemplateToolsNode;
};
type DefaultState = {
  kind: "Default";
};
type TestLoadingState = {
  kind: "TestLoading";
};
type TestSuccessState = {
  kind: "TestSuccess";
  results: DiffResults[];
  showInputTokens: boolean;
  showTemplateTokens: boolean;
};
type TestFailureState = {
  kind: "TestFailure";
  message: string;
};
type State = DefaultState | TestLoadingState | TestSuccessState | TestFailureState;
type TokensProps = {
  tokens: string[];
};
type CompareFileToTemplateInfo = {
  asyncJobId: string;
  onSuccess: () => void;
};

function Tokens({ tokens }: TokensProps) {
  return (
    <div className="OrganizationTemplateTools--test-results--tokens">
      {tokens.map((token, index) => (
        <p key={token} className="OrganizationTemplateTools--test-results--token">
          "{token}"{index < tokens.length - 1 && ","}
        </p>
      ))}
    </div>
  );
}

function OrganizationTemplateTools({ template }: Props) {
  const navigate = useNavigate();
  const moveTools = useMoveTools();
  const updateTemplateMutateFn = useMutation(UpdateOrganizationDocumentTemplateMutation);
  const compareFileToTemplateMutateFn = useMutation(CompareFileToTemplateMutation);
  const [getCompareFileToTemplateResult, { data: resultData, error: resultError, stopPolling }] =
    useLazyQuery(CompareFielToTemplateResultQuery, {
      pollInterval: 3000,
      variables: { templateId: template.id },
    });
  const [state, setState] = useState<State>({ kind: "Default" });
  const compareFileToTemplateInfo = useRef<CompareFileToTemplateInfo>();

  const setLoading = useCallback(() => {
    setState({ kind: "TestLoading" });
  }, []);

  const setFailure = useCallback(
    (error: { message: string; graphQLErrors?: readonly { message: string }[] } | string) => {
      setState({
        kind: "TestFailure",
        message:
          typeof error === "string" ? error : error.graphQLErrors?.[0]?.message || error.message,
      });
    },
    [],
  );

  const toggleInputTokens = useCallback(() => {
    const successState = state as TestSuccessState;
    setState({ ...successState, showInputTokens: !successState.showInputTokens });
  }, [state]);

  const toggleTemplateTokens = useCallback(() => {
    const successState = state as TestSuccessState;
    setState({ ...successState, showTemplateTokens: !successState.showTemplateTokens });
  }, [state]);

  function onBack() {
    const { search } = location;
    // BIZ-7111: Always use the new hardcoded tools path.
    if (moveTools) {
      navigate(`/tools/${TEMPLATES_ROUTE}/${template.id}/edit/${search}`);
    } else {
      navigate(`/templates/${template.id}/edit/${search}`);
    }
  }

  function updateOverrideDesignationOrder() {
    updateTemplateMutateFn({
      variables: {
        input: {
          id: template.id,
          activationState: template.activationState,
          overrideDesignationOrder: !template.overrideDesignationOrder,
          name: template.name,
          displayName: template.displayName,
          permalink: template.permalink,
        },
      },
    });
  }

  function updateSuppressNonBorrowerSpouse() {
    updateTemplateMutateFn({
      variables: {
        input: {
          id: template.id,
          activationState: template.activationState,
          suppressNonBorrowerSpouse: !template.suppressNonBorrowerSpouse,
          name: template.name,
          displayName: template.displayName,
          permalink: template.permalink,
        },
      },
    });
  }

  function updatePresetDesignationType(newType: TemplatePresetDesignationType) {
    updateTemplateMutateFn({
      variables: {
        input: {
          id: template.id,
          activationState: template.activationState,
          name: template.name,
          displayName: template.displayName,
          permalink: template.permalink,
          presetDesignationType: newType,
        },
      },
    });
  }

  const compareFileToTemplate = useCallback(
    async ({ s3Key }: { s3Key: string }, onSuccess: () => void) => {
      // <Uploader /> handles error
      const { data } = await compareFileToTemplateMutateFn({
        variables: {
          input: {
            fileHandle: s3Key,
            templateId: template.id,
          },
        },
      });

      compareFileToTemplateInfo.current = {
        asyncJobId: data!.compareFileToTemplate!.asyncJobId,
        onSuccess,
      };

      getCompareFileToTemplateResult();
    },
    [template],
  );

  useEffect(() => {
    if (resultError) {
      setFailure(resultError);
      stopPolling();
    } else if (resultData) {
      const { asyncJobId, onSuccess } = compareFileToTemplateInfo.current!;

      const template = resultData.template as CompareFileToTemplateResultTemplate;
      const asyncJob = template.asyncJobs.find(({ id }) => id === asyncJobId);
      if (asyncJob?.status === AsyncJobStatus.COMPLETED) {
        onSuccess();
        setState({
          kind: "TestSuccess",
          results: asyncJob.result!.diffResults,
          showInputTokens: false,
          showTemplateTokens: false,
        });
        stopPolling();
      } else if (asyncJob?.status === AsyncJobStatus.FAILED) {
        setFailure(asyncJob.errors![0]!);
        stopPolling();
      }
    }
  }, [resultData, resultError]);

  const documentUrl = template.documentBundle.documents.edges[0]?.document?.s3UploadedAsset?.url;

  const designationTypesList = Object.values(TemplatePresetDesignationType).map((value) => ({
    label: value,
    value,
  }));
  const modalTitleId = useId();

  if (!useMatch({ path: "tools", end: false }) && moveTools) {
    return ToolsRedirect();
  }

  return (
    <Overlay>
      <div role="dialog" aria-labelledby={modalTitleId} className="OrganizationTemplateTools">
        <div className="OrganizationTemplateTools--Header">
          <div className="OrganizationTemplateTools--Header--Select">
            <span id={modalTitleId}>
              <FormattedMessage
                id="a70fa266-7238-4b18-bf4c-3ab800635d23"
                defaultMessage="Preset Designation Type: "
              />
            </span>
            <DeprecatedSelectInput
              items={designationTypesList}
              onChange={updatePresetDesignationType}
              value={template.presetDesignationType}
              automationId="preset-designation-type"
            />
          </div>
          <div className="OrganizationTemplateTools--Header--Toggle">
            <span id="suppress-non-borrower-spouse-toggle">
              <FormattedMessage
                id="6be4cdae-3751-47bd-a3d7-e3172e565e6f"
                defaultMessage="Suppress Non Borrower Spouse: "
              />
            </span>
            <BinaryToggle
              aria-labelledby="suppress-non-borrower-spouse-toggle"
              value={template.suppressNonBorrowerSpouse}
              onChange={updateSuppressNonBorrowerSpouse}
              automationId="suppress-non-borrower-spouse-toggle"
              size="small"
            />
          </div>
          <div className="OrganizationTemplateTools--Header--Toggle">
            <span id="override-designation-order-toggle">
              <FormattedMessage
                id="4e735e62-866f-4283-953f-5038c94167dd"
                defaultMessage="Override Designation Order: "
              />
            </span>
            <BinaryToggle
              aria-labelledby="override-designation-order-toggle"
              value={template.overrideDesignationOrder}
              onChange={updateOverrideDesignationOrder}
              automationId="override-designation-order-toggle"
              size="small"
            />
          </div>
          <Uploader
            className="OrganizationTemplateTools--Header--test-uploader"
            createFile={compareFileToTemplate}
            onBeforeUpload={setLoading}
            onUploadFailure={setFailure}
            isTemporary
          >
            <Button isLoading={state.kind === "TestLoading"} buttonColor="action" variant="primary">
              <FormattedMessage
                id="af3159ee-1837-490e-959e-1112748a2be7"
                defaultMessage="Test Document"
              />
            </Button>
          </Uploader>
          <Button buttonColor="dark" variant="secondary" onClick={onBack}>
            <FormattedMessage id="bf4d5e3f-4ddc-4b18-85c0-5b1ad65e3a1c" defaultMessage="Back" />
          </Button>
        </div>
        {["TestSuccess", "TestFailure"].includes(state.kind) && (
          <div className="OrganizationTemplateTools--test-results">
            <p className="OrganizationTemplateTools--test-results--header">
              <FormattedMessage
                id="61cbbca9-670a-4185-877f-fbcb9c3eb736"
                defaultMessage="Test Results"
              />
            </p>
            {state.kind === "TestSuccess" &&
              state.results.map((result) => (
                <div key={result.pageIndex}>
                  <p className="OrganizationTemplateTools--test-results--subheader">
                    <FormattedMessage
                      id="5c7edad1-924b-471a-8621-6411ee58eca4"
                      defaultMessage="Page {number}"
                      values={{ number: result.pageIndex + 1 }}
                    />
                  </p>
                  {result.tokenDiff.length > 0 ? (
                    <>
                      <p className="OrganizationTemplateTools--test-results--tokens-header">
                        <FormattedMessage
                          id="7b7b09dc-b773-4a17-929f-9857769776e5"
                          defaultMessage="Token Diff"
                        />
                      </p>
                      <Tokens tokens={result.tokenDiff} />

                      <p className="OrganizationTemplateTools--test-results--tokens-header">
                        <Link underlined={false} onClick={toggleInputTokens}>
                          <FormattedMessage
                            id="74248c20-76bb-49b9-b271-34f2b4c01331"
                            defaultMessage="Pdf Tokens"
                          />
                        </Link>
                      </p>
                      {state.showInputTokens && <Tokens tokens={result.inputTokens} />}

                      <p className="OrganizationTemplateTools--test-results--tokens-header">
                        <Link underlined={false} onClick={toggleTemplateTokens}>
                          <FormattedMessage
                            id="4263cc26-29d7-4bc3-84fd-b8e43cf2400e"
                            defaultMessage="Template Tokens"
                          />
                        </Link>
                      </p>
                      {state.showTemplateTokens && <Tokens tokens={result.templateTokens} />}
                    </>
                  ) : (
                    <p className="OrganizationTemplateTools--test-results--match">
                      <FormattedMessage
                        id="d7a8e56e-3d4a-4db5-a528-39ff7a692d1c"
                        defaultMessage="Match"
                      />
                    </p>
                  )}
                </div>
              ))}
            {state.kind === "TestFailure" && (
              <>
                <p>
                  <FormattedMessage
                    id="b04bf379-f3d7-4330-9427-4576b1c96140"
                    defaultMessage="Error"
                  />
                </p>
                <p className="OrganizationTemplateTools--test-results--error">{state.message}</p>
              </>
            )}
          </div>
        )}
        <div className="OrganizationTemplateTools--Content">
          {documentUrl ? (
            <div className="OrganizationTemplateTools--Content--Document">
              <PDFViewer
                className="OrganizationTemplateTools--Content--Document--Pdf"
                url={documentUrl}
              />
              <Button
                buttonColor="action"
                variant="secondary"
                className="OrganizationTemplateTools--Content--Document--Action"
                onClick={() => {
                  window.open(documentUrl, "_blank");
                }}
                automationId="view-document-button"
              >
                <FormattedMessage
                  id="de167973-29b1-40d1-b335-a02f418483f8"
                  defaultMessage="View Document"
                />
              </Button>
            </div>
          ) : (
            <div className="OrganizationTemplateTools--DocumentError">
              <FormattedMessage
                id="4c53145e-bac5-4c3a-b904-a5b87dbf37e9"
                defaultMessage="No s3 url found for the document"
              />
            </div>
          )}
          <div className="OrganizationTemplateTools--Content--Info">
            {template.organizationDocumentTemplatePages.map((page, index) => {
              return (
                <div className="OrganizationTemplateTools--Content--Info--Page" key={index}>
                  <div className="OrganizationTemplateTools--Content--Info--Page--Header">
                    <FormattedMessage
                      id="7655c2cb-7365-4acd-9f9e-3c1cf419aa61"
                      defaultMessage={`Page {pageNumber}`}
                      values={{ pageNumber: page.pageNumber + 1 }}
                    />
                  </div>
                  <div className="OrganizationTemplateTools--Content--Info--PageText">
                    {JSON.stringify(page.pageText)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Overlay>
  );
}

export default () => {
  const templateId = useParams().templateId!;
  return (
    <QueryWithLoading query={TemplateToolsQuery} variables={{ templateId }}>
      {({ data }) => {
        const templateNode = data!.node;
        if (templateNode?.__typename !== "OrganizationDocumentTemplate") {
          throw new Error(`Expected template, found ${templateNode?.__typename}.`);
        }
        return <OrganizationTemplateTools template={templateNode} />;
      }}
    </QueryWithLoading>
  );
};
