import "common/form/deprecated_form.scss";

import { useEffect, useState } from "react";
import { type InjectedFormProps, reduxForm } from "redux-form";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import compose from "util/compose";
import SaveButton from "common/core/save_button";
import { DeprecatedFormGroup as FormGroup } from "common/form/group";
import { DeprecatedFormGroupErrors as FormGroupErrors } from "common/form/group_errors";
import { DeprecatedEmailField } from "common/form/fields/email";
import { composeValidators, getFormValues } from "util/form";
import { validateEmailFormat } from "validators/account";
import { validatePresence } from "validators/form";
import type { BillingSettings_node_Organization as Organization } from "common/settingsv2/sidebar_settings/billing/billing_query.graphql";
import { useMutation } from "util/graphql";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { Card, CardHeading, CardSection, CardText } from "common/core/card";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES } from "constants/notifications";

import Styles from "./contact.module.scss";
import UpdateOrganizationBillingEmailMutation from "./update_organization_billing_email_mutation.graphql";

const messages = defineMessages({
  billingContactSuccess: {
    id: "4891a197-6609-4537-a543-dfa196684153",
    defaultMessage: "Billing email successfully updated",
  },
});

function validate(values: FormValues) {
  return composeValidators(
    validateEmailFormat({ field: "billingContact", label: "Billing Email" }),
    validatePresence({ field: "billingContact", label: "Billing Email" }),
  )(values);
}
type FormValues = {
  billingContact: string | null;
};

type GetFormValueProps = {
  formValues: FormValues;
};

type FormProps = InjectedFormProps<FormValues, Props>;

type Props = {
  admin: boolean;
  organization: Organization;
};

type InnerProps = Props & FormProps & GetFormValueProps;

function ContactDetails({
  initialize,
  handleSubmit,
  admin,
  submitting,
  organization: { id, billingEmail },
}: InnerProps) {
  useEffect(() => {
    initialize({
      billingContact: billingEmail,
    });
  }, []);
  const intl = useIntl();
  const updateOrganizationBillingEmail = useMutation(UpdateOrganizationBillingEmailMutation);

  const [status, setStatus] = useState<"error" | "success" | null>(null);

  const save = async ({ billingContact }: FormValues) => {
    try {
      await updateOrganizationBillingEmail({
        variables: { input: { id, billingEmail: billingContact! } },
      });
      setStatus("success");
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        message: intl.formatMessage(messages.billingContactSuccess),
      });
    } catch {
      setStatus("error");
    }

    if (!admin) {
      return null;
    }
  };

  return (
    <form data-automation-id="contact-form" onSubmit={handleSubmit(save)}>
      <Card
        loading={submitting}
        footer={
          <>
            <SaveButton submitting={submitting} />
          </>
        }
      >
        <CardSection>
          <CardHeading>
            <label htmlFor="billingContact">
              <FormattedMessage
                id="910125c4-6db9-40e0-bf84-729b0529b26c"
                defaultMessage="Billing Email"
              />
            </label>
          </CardHeading>
          <CardText>
            <p className={Styles.info}>
              <FormattedMessage
                id="c3438686-ef9b-4f1c-9a0d-f36a766ef87c"
                defaultMessage="Billing email is used for receipts and invoices."
              />
            </p>
          </CardText>
          <FormGroup className={Styles.contact} disableFormRowStyle fields={["billingContact"]}>
            <DeprecatedEmailField
              id="billingContact"
              name="billingContact"
              useStyledInput
              data-automation-id="billing-contact-field"
            />
            <FormGroupErrors fields={["billingContact"]} />
          </FormGroup>
        </CardSection>
        {status === "error" && <MutationErrorModal onClick={() => setStatus(null)} />}
      </Card>
    </form>
  );
}

export default compose(
  reduxForm({
    form: "ContactDetails",
    validate,
  }),
  getFormValues<InnerProps>("ContactDetails"),
)(ContactDetails);
