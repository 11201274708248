import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { MeatballMenu } from "common/core/table/meatball_menu";

import type { Templates_organization_Organization_templates_edges_template as Template } from "../templates_query.graphql";

type Props = {
  onEdit: (id: string) => void;
  onDelete: (template: Template) => void;
  template: Template;
  automationId: string;
};

const MESSAGES = defineMessages({
  menuTitle: {
    id: "d64e0617-b13d-4a21-99ef-f47b21f9b936",
    defaultMessage: "Template actions",
  },
});

export function TemplateMeatballMenu({ template, onEdit, onDelete, automationId }: Props) {
  const intl = useIntl();
  const menuItems = [
    {
      iconName: "pencil",
      automationId: "edit-template",
      onClick: () => onEdit(template.id),
      content: (
        <FormattedMessage
          id="1a04abbd-c808-48b0-b567-1d17d11a7f3a"
          defaultMessage="Edit template"
        />
      ),
    },
    {
      danger: true,
      iconName: "delete",
      automationId: "document-uploader-delete-document",
      onClick: () => onDelete(template),
      content: (
        <FormattedMessage
          id="ea9007c8-8e71-4a57-a488-9ac51a2bd91a"
          defaultMessage="Delete template"
        />
      ),
    },
  ];

  return (
    <MeatballMenu
      automationId={automationId}
      menuTitle={intl.formatMessage(MESSAGES.menuTitle)}
      menuItems={menuItems}
    />
  );
}
