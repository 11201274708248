import { FormattedMessage, useIntl } from "react-intl";

import { SettingsTitle } from "common/settingsv2/common";
import Tab from "common/core/tabs/tab";
import LoadingIndicator from "common/core/loading_indicator";
import { useActiveOrganization } from "common/account/active_organization";
import { NetworkStatus, useQuery } from "util/graphql";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import { ButtonColor } from "./button_color";
import { WebThemeColor } from "./web_theme_color";
import { TertiaryButtonColor } from "./tertiary_button_color";
import { InlineLinkColor } from "./inline_link_color";
import BrandColorsQuery from "./index.query.graphql";

export const BRAND_COLORS_ROUTE = "brand-colors";

export function BrandColorsTab() {
  return (
    <Tab to={BRAND_COLORS_ROUTE}>
      <FormattedMessage id="d9e77494-6512-457c-a76d-c7c5f43a6f12" defaultMessage="Brand colors" />
    </Tab>
  );
}

export function BrandColorsSettings() {
  useA11y().useDocumentEntitler({
    title: useIntl().formatMessage(useDocumentTitles().settingsBrandColors),
  });
  const [activeOrganizationId] = useActiveOrganization();

  const { data, networkStatus } = useQuery(BrandColorsQuery, {
    variables: { organizationId: activeOrganizationId! },
  });
  // using networkStatus to only show loading screen on initial load but not on any refetches
  const loading = networkStatus === NetworkStatus.loading;

  if (loading) {
    return <LoadingIndicator />;
  }

  const organization = data ? data.organization! : null;
  if (!organization) {
    throw new Error(`Expected organization, got nothing.`);
  }
  if (organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  const organizationBrand = organization.organizationBrand!;
  return (
    <>
      <SettingsTitle>
        <WebThemeColor organizationBrand={organizationBrand} />
        <ButtonColor organizationBrand={organizationBrand} />
        <TertiaryButtonColor organizationBrand={organizationBrand} />
        <InlineLinkColor organizationBrand={organizationBrand} />
      </SettingsTitle>
    </>
  );
}
