import { defineMessages, useIntl } from "react-intl";

import { Card, CardText } from "common/core/card";
import { Heading } from "common/core/typography";

const MESSAGES = defineMessages({
  heading: {
    id: "9994ad6a-cfff-41b8-99dc-b7c0fb4da6f4",
    defaultMessage: "Your plan is managed at the company level",
  },
  text: {
    id: "a99758f0-504f-482e-a33f-71cf60baf26a",
    defaultMessage:
      "Your company's primary billing contact is managing this organization's plan details. If you have any questions, reach out to your account administrator.",
  },
});

export function ManagedBillingCard() {
  const intl = useIntl();
  return (
    <Card>
      <Heading textStyle="subtitleSmall" level="h2">
        {intl.formatMessage(MESSAGES.heading)}
      </Heading>
      <CardText>{intl.formatMessage(MESSAGES.text)}</CardText>
    </Card>
  );
}
