import { useCallback } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { TextInput } from "common/core/form/text";
import { isAriaInvalid, FieldErrorMessage } from "common/core/form/error";
import Button from "common/core/button";
import Link from "common/core/link";
import { contrastIsAccessible, hexToRGBComponents } from "util/color";
import type { UseFormReturn, FieldPath, FieldPathValue } from "common/core/form";

import Styles from "./index.module.scss";

type ColorFormValues = Partial<{
  inlineLinkColor: string;
  primaryColor: string;
  primaryButtonColor: string;
  tertiaryButtonColor: string;
}>;
type Colors = keyof ColorFormValues;
type Props<T extends ColorFormValues> = {
  placeholderText: string;
  // preferably 'colorFieldName' would be typed in such a way that it would equate to all possible Paths that would map to strings in the form value record (T).
  // That is difficult to represent, so I am going with an intersection between Path<T> (aka all possible field paths) with hard-coded and known color fields that will be passed in.
  colorFieldName: FieldPath<T> & Colors;
  isUpdating: boolean;
  form: UseFormReturn<T>;
  defaultColor?: string;
  setResetValue: (value: boolean) => void;
};

const WHITE_RGB = Object.freeze({ r: 255, g: 255, b: 255 });
const MESSAGES = defineMessages({
  hexPattern: {
    id: "da1c7edd-260b-41a1-aa26-392646223d7a",
    defaultMessage: "Please use a 6-digit hex color code",
  },
  fieldIsRequired: {
    id: "acc309cd-406d-4a44-8028-513e4cfe22e1",
    defaultMessage: "This field is required",
  },
});
const CONTRAST_MESSAGE = (
  <FormattedMessage
    id="be831068-7f9c-4717-aca0-f0cdbe9c47d5"
    defaultMessage="This color is not dark enough to meet the Web Content Accessibility 2.1 AA Guidelines for color contrast, and cannot be saved. Please reset to default or enter another color code. For more information, visit <link>webaim.org/resources/contrastchecker</link>."
    values={{
      link: (msg) => <Link href="https://webaim.org/resources/contrastchecker/">{msg}</Link>,
    }}
  />
);

function accessibleContrastValidator(value: string | undefined): boolean {
  return Boolean(value && contrastIsAccessible(WHITE_RGB, hexToRGBComponents(value)));
}

function ColorUpdater<T extends ColorFormValues>({
  placeholderText,
  colorFieldName,
  isUpdating,
  form,
  setResetValue,
}: Props<T>) {
  const intl = useIntl();
  const defaultColor = getComputedStyle(document.body).getPropertyValue("--primary-50");
  const { formState, watch, setValue, register } = form;
  const { errors } = formState;
  const handleReset = useCallback(() => {
    setValue(colorFieldName, defaultColor as FieldPathValue<T, typeof colorFieldName>, {
      shouldValidate: true,
    });

    setResetValue(true);
  }, [colorFieldName, defaultColor]);
  const targetColor = watch(colorFieldName);
  const colorError = errors[colorFieldName];

  return (
    <>
      <div className={Styles.buttonColorPreviewInputContainer}>
        {errors[colorFieldName]?.type === "pattern" ? (
          <div className={Styles.buttonColorPreviewTransparent} />
        ) : (
          <div className={Styles.buttonColorPreview} style={{ backgroundColor: targetColor }} />
        )}

        <div className={Styles.buttonColorPreviewInput}>
          <TextInput
            placeholder={placeholderText}
            aria-invalid={isAriaInvalid(errors[colorFieldName])}
            {...register(colorFieldName, {
              required: intl.formatMessage(MESSAGES.fieldIsRequired),
              validate: accessibleContrastValidator,
              pattern: {
                message: intl.formatMessage(MESSAGES.hexPattern),
                value: /^#[0-9A-Fa-f]{6}$/,
              },
            })}
          />
        </div>
        {targetColor !== defaultColor && !isUpdating && (
          <Button
            buttonColor="action"
            variant="tertiary"
            className={Styles.resetButton}
            onClick={handleReset}
          >
            <FormattedMessage
              id="7737d91b-9905-4fb1-9e31-8ac6ff0227ac"
              defaultMessage="Reset to default"
            />
          </Button>
        )}
      </div>
      {colorError && (
        <div className={Styles.errorMessage}>
          <FieldErrorMessage
            inputName={colorFieldName}
            message={
              colorError.type === "validate" ? CONTRAST_MESSAGE : (colorError.message as string)
            }
          />
        </div>
      )}
    </>
  );
}

export default ColorUpdater;
