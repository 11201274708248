import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";

import { Paragraph } from "common/core/typography";
import { Hr } from "common/core/horizontal_rule";
import { DashboardBody } from "common/dashboard";
import Table from "common/core/table";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { EASYLINK_DASHBOARD_PATH } from "util/routes";
import { COMMON_MESSAGES } from "common/dashboard/easy_link/common";

import type { Templates_organization_Organization_templates_edges_template_easylinks as Easylink } from "../organization_templates/templates_query.graphql";
import Styles from "./index.module.scss";

export function EasylinkPreviewSection({ easylinks }: { easylinks: Easylink[] }) {
  const intl = useIntl();
  const columns = [
    {
      Header: <FormattedMessage id="95d39fb3-5b8f-46a2-8ebf-e583cd368f6f" defaultMessage="Name" />,
      render: (easylink: Easylink) =>
        easylink.name || intl.formatMessage(COMMON_MESSAGES.NAME_NOT_PROVIDED),
    },
    {
      Header: (
        <FormattedMessage id="66617094-9200-4078-aa5b-0d1e560c0660" defaultMessage="Status" />
      ),
      render: (easylink: Easylink) => {
        const isActive = easylink.active;
        return (
          <div className={Styles.status}>
            <div className={classNames(Styles.statusDot, { [Styles.active]: isActive })} />
            <FormattedMessage
              id="0aaf4abf-a63d-48a1-8fcd-967451cf1a3e"
              defaultMessage="{isActive, select, true{Active} other{Inactive}}"
              values={{ isActive }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Hr className={Styles.divider} />
      <div>
        <Paragraph size="defaultSize" className={Styles.title}>
          <FormattedMessage
            tagName="strong"
            id="68223672-1ff1-414a-bb69-d1b78e0bc1d5"
            defaultMessage="This template is being used in {count, plural, one{1 EasyLink} other{# EasyLinks}}"
            values={{ count: easylinks.length }}
          />
        </Paragraph>

        <Paragraph size="small" textColor="subtle">
          <FormattedMessage
            id="fe6f173d-3b08-4c41-b710-af7e666992a6"
            defaultMessage={
              "Changes made to this template will also impact the following EasyLinks. <a>Manage EasyLinks {icon} </a>"
            }
            values={{
              a: (link) => <Link href={EASYLINK_DASHBOARD_PATH}>{link}</Link>,
              icon: <Icon name="arrow-up-right-square" />,
            }}
          />
        </Paragraph>
        <div className={Styles.table}>
          <DashboardBody>
            <Table data={easylinks} columns={columns} totalItems={easylinks.length} extraPadding />
          </DashboardBody>
        </div>
      </div>
    </>
  );
}
