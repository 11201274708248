import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { ButtonStyledLink } from "common/core/button/button_styled_link";
import Link from "common/core/link";
import Icon from "common/core/icon";

import Styles from "./index.module.scss";

export function EmptyStateContainer({ children }: { children: ReactNode }) {
  return <div className={Styles.emptyState}>{children}</div>;
}

export function EmptyStateTitle({
  children,
  imageSrc,
}: {
  children: ReactNode;
  imageSrc?: string;
}) {
  return (
    <div className={Styles.emptyStateTitle}>
      {imageSrc && (
        <img src={imageSrc} className={Styles.emptyStateTitleImage} alt="" aria-hidden="true" />
      )}
      <h2>{children}</h2>
    </div>
  );
}

export function EmptyStateDescription({ children }: { children: ReactNode }) {
  return <div className={Styles.emptyStateDescription}>{children}</div>;
}

export function EmptyStateButtons({ children }: { children: ReactNode }) {
  return <div className={Styles.emptyStateButtons}>{children}</div>;
}

export function HowItWorksLink({ href }: { href: string }) {
  return (
    <ButtonStyledLink
      buttonColor="action"
      variant="secondary"
      withIcon={{ name: "arrow-up-right-square", placement: "right" }}
      href={href}
    >
      <FormattedMessage id="250b6061-024c-4930-8b53-81b2d208f4d6" defaultMessage="How it works" />
    </ButtonStyledLink>
  );
}

export function EmptyStateTileGrid({ children }: { children: ReactNode }) {
  return <div className={Styles.tileGrid}>{children}</div>;
}

export function EmptyStateTile({
  iconName,
  text,
  subText,
  "data-automation-id": automationId,
  ...props
}: {
  iconName: string;
  text: ReactNode;
  subText?: ReactNode;
  onClick: () => void;
  "data-automation-id"?: string;
}) {
  return (
    <Link
      openInCurrentTab
      underlined={false}
      black
      className={Styles.tile}
      automationId={automationId}
      {...props}
    >
      <Icon name={iconName} />
      <div className={Styles.tileTextWrapper}>
        <span className={Styles.tileText}>{text}</span>
        {subText && <span className={Styles.tileSubtext}>{subText}</span>}
      </div>
    </Link>
  );
}
