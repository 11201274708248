import { type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import {
  Footer,
  MainHeading,
  NextStepButton,
  ResponsiveWrapper,
  VerticalStepIndicator,
} from "common/signer/common";
import ProofLogoSeal from "assets/images/logos/proof-seal.svg";
import Svg from "common/core/svg";

import Styles from "./IdentifyAccountRecovery.module.scss";
import type {
  SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
  SignerLanding_viewer as Viewer,
} from "../index.query.graphql";
import { LandingWrapper, useHandleReadyToSign } from "./common";

type Props = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  handleBlockedAction: (action: () => void) => void;
};

type InnerProps = Props & {
  renderExpiredOrInactiveContent: () => ReactNode;
};

function ProofLanding({
  documentBundle,
  viewer,
  renderExpiredOrInactiveContent,
  handleBlockedAction,
}: InnerProps) {
  const handleContinue = useHandleReadyToSign(documentBundle, viewer);

  const expiredOrInactive = renderExpiredOrInactiveContent();
  if (expiredOrInactive) {
    return <>{expiredOrInactive}</>;
  }

  const brandLogo = documentBundle.brandingInfo?.organizationLogoUrl;
  const brandName =
    documentBundle.brandingInfo?.organizationName ||
    documentBundle.organizationTransaction.publicOrganization.organizationBrand.name;

  return (
    <ResponsiveWrapper centered fullHeight>
      {brandLogo && (
        <div className={Styles.logosContainer}>
          <img className={Styles.logo} src={brandLogo} alt="" />
          <svg className={Styles.dash} role="img">
            <path d="M1.5 1.61133H26.5"></path>
          </svg>
          <Svg className={Styles.logo} src={ProofLogoSeal} alt="" />
        </div>
      )}
      <MainHeading>
        <FormattedMessage
          id="d597c94e-7b0b-4500-b561-d252d2a9c12c"
          defaultMessage="To recover your account, <b>{brandName}</b> requires identity verification with <b>Proof</b>."
          values={{ brandName, b: (chunks) => <strong>{chunks}</strong> }}
        />
      </MainHeading>

      <p className={Styles.subtext}>
        {/* TODO: We want the 'will not share this information text' to be dynamic based on a TBD setting(s) */}
        <FormattedMessage
          id="81ec9c87-5008-4b52-96d2-37e057b635b7"
          defaultMessage="To ensure security, we need to collect some information to confirm your identity. Proof will not share this information with {brandName}."
          values={{
            brandName,
          }}
        />
      </p>

      <VerticalStepIndicator
        className={Styles.stepIndicator}
        listItems={[
          {
            item: (
              <FormattedMessage
                id="d9a14421-bac7-434e-a761-b561a2c3d308"
                defaultMessage="Verify your information"
              />
            ),
            parentheses: (
              <FormattedMessage
                id="10d8acbd-e801-4803-a57e-5fefedb0d466"
                defaultMessage="(2 min)"
              />
            ),
          },
          {
            item: (
              <FormattedMessage
                id="bea6f9ba-04cf-4012-b877-b3c4ce6b9c4b"
                defaultMessage="Verify your identity"
              />
            ),
            parentheses: (
              <FormattedMessage
                id="10d8acbd-e801-4803-a57e-5fefedb0d466"
                defaultMessage="(5 min)"
              />
            ),
          },
        ]}
      />

      <Footer
        nextStepButton={
          <NextStepButton
            automationId="get-started-button"
            onClick={() => handleBlockedAction(handleContinue)}
            text={
              <FormattedMessage
                id="2e23e694-4550-4766-a424-2772f353ce25"
                defaultMessage="Begin verification"
              />
            }
          />
        }
      />
    </ResponsiveWrapper>
  );
}

function ProofLandingWrapper(props: Props) {
  return (
    <LandingWrapper automationPrefix="authenticated-esign-landing" {...props}>
      {(renderExpiredOrInactiveContent) => (
        <ProofLanding renderExpiredOrInactiveContent={renderExpiredOrInactiveContent} {...props} />
      )}
    </LandingWrapper>
  );
}

export { ProofLandingWrapper as IdentifyAccountRecoveryLanding };
