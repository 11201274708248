import { useRef } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useReactToPrint } from "react-to-print";

import IdentifyEasyLinkPrintable from "assets/images/identify-easylink-printable.svg";
import Svg from "common/core/svg";
import Button from "common/core/button";

import type { Easylinks_node_Organization_organizationBrand as OrganizationBrand } from "../index.query.graphql";
import Styles from "./print.module.scss";

const MESSAGES = defineMessages({
  identifyDocTitle: {
    id: "99400349-37c5-4f21-9614-1ad6243f1b65",
    defaultMessage: "Proof - Identify EasyLink printout",
  },
});

type Props = {
  qrStream: string;
  orgLogoUrl?: OrganizationBrand["organizationLogoUrl"];
};

function IdentifyPrintable({ qrStream, orgLogoUrl }: Props) {
  const svgDataUrlSrc = `data:image/svg+xml;base64,${btoa(qrStream)}`;

  return (
    <>
      <Svg
        src={IdentifyEasyLinkPrintable}
        alt="Template for Identify EasyLink printable"
        className={Styles.printableBase}
      />
      {orgLogoUrl && <Svg src={orgLogoUrl} alt="" className={Styles.identifyLogo} />}
      <Svg src={svgDataUrlSrc} alt="" className={Styles.identifyQrCode} />
    </>
  );
}

export function PrintSection(props: Props) {
  const intl = useIntl();
  const contentRef = useRef<HTMLDivElement>(null);

  const reactToPrint = useReactToPrint({
    contentRef,
    documentTitle: intl.formatMessage(MESSAGES.identifyDocTitle),
  });

  return (
    <div className={Styles.printSection}>
      <div className={Styles.previewContainer}>
        <IdentifyPrintable {...props} />
      </div>
      <div className={Styles.printableContainer} ref={contentRef}>
        <IdentifyPrintable {...props} />
      </div>
      <Button
        buttonColor="action"
        variant="secondary"
        className={Styles.printButton}
        onClick={() => reactToPrint()}
      >
        <FormattedMessage id="d6b92ace-1924-4ed8-8a3f-c383ce125ba2" defaultMessage="Print .pdf" />
      </Button>
    </div>
  );
}
