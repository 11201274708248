import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { ChargeStatuses } from "graphql_globals";
import type { Charge } from "common/settingsv2/sidebar_settings/billing/recent_payments/charge_fragment.graphql";
import { useQuery } from "util/graphql/query";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import { Heading, Paragraph } from "common/core/typography";
import { format } from "common/core/format/date";
import { Badge } from "common/core/badge";
import Button from "common/core/button";
import {
  PaymentCopy,
  PaymentButton,
  showToast,
  MESSAGES,
} from "common/settingsv2/sidebar_settings/profile/payment_method";
import CustomerProfileQuery from "common/settingsv2/sidebar_settings/profile/customer_profile_query.graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { Card, CardHeading } from "common/core/card";
import { CardPaymentMethod } from "common/settingsv2/sidebar_settings/billing/payment_settings/payment";
import PaymentForm from "common/settingsv2/sidebar_settings/profile/payment_form";
import { MutationErrorModal } from "common/settingsv2/modals/mutation_error_modal";
import { useMatchScreenClass } from "common/core/responsive";
import { useFetchPersonalPayments } from "common/settingsv2/sidebar_settings/billing/recent_payments/fetcher";
import FormattedPrice from "common/core/format/formatted_price";
import { Type } from "common/settingsv2/sidebar_settings/billing/recent_payments/table";
import Link from "common/core/link";
import PriceBreakdown from "common/transactions/price_breakdown";
import MobileFullPageModal from "common/modals/full_page_responsive_modal";
import { useMutation } from "util/graphql";
import DeleteCardMutation from "common/settingsv2/sidebar_settings/profile/delete_card_mutation.graphql";
import WorkflowModalWrapper from "common/modals/workflow_modal";

import Styles from "../index.module.scss";
import ProfileStyles from "./index.module.scss";

const fetchCount = 25;

const STATUSES = defineMessages({
  [ChargeStatuses.PAID]: {
    id: "83224029-6df6-49e3-be64-6a0c3ad35381",
    defaultMessage: "Paid",
  },
  [ChargeStatuses.CANCELED]: {
    id: "3b84a0f7-2d69-44b6-a693-31308dd2abcf",
    defaultMessage: "Cancelled",
  },
  [ChargeStatuses.CHARGING]: {
    id: "56d5dfc2-6d22-4f88-97a6-786004cbd212",
    defaultMessage: "Pending",
  },
  [ChargeStatuses.UNPAID]: {
    id: "525044ad-938d-40c5-a498-8e6caa47f2d3",
    defaultMessage: "Unpaid",
  },
  [ChargeStatuses.PENDING_NO_CARD]: {
    id: "6547a435-ab35-4328-99df-ba322585f5ef",
    defaultMessage: "Processing",
  },
  [ChargeStatuses.QUEUED]: {
    id: "6547a435-ab35-4328-99df-ba322585f5ef",
    defaultMessage: "Queued",
  },
  [ChargeStatuses.FAILED]: {
    id: "3d413168-6fb2-409b-9b2a-83083e84311b",
    defaultMessage: "Failed",
  },
});

const LABELS = defineMessages({
  id: {
    id: "c7c73e13-e2a3-46da-8d22-01590321cba0",
    defaultMessage: "Transaction ID",
  },
  type: {
    id: "7421f73f-ed1f-45fe-92cb-beb037c33792",
    defaultMessage: "Type",
  },
  amount: {
    id: "2b119093-f9d8-47e0-9e70-2559df02b852",
    defaultMessage: "Amount",
  },
  payments: { id: "d36b4b32-94f7-47dd-9a0d-bc114e9ffbed", defaultMessage: "Payments" },
  payment: { id: "e0bc50f2-ecbd-448a-aedd-ef573d88c669", defaultMessage: "Payment" },
  noPayments: {
    id: "0a1e4c11-3345-481e-bc49-84a9e4466fa5",
    defaultMessage: "There are no recent payments.",
  },
  unavailable: {
    id: "0ffeee79-4622-4da7-b513-99bef3272cc3",
    defaultMessage: "Unavailable",
  },
  none: {
    id: "ea31d7f9-98ad-4afd-bab1-f518a29956db",
    defaultMessage: "None",
  },
});

const PaymentCard = ({ payment }: { payment: Charge }) => {
  const intl = useIntl();
  const [showReceipt, setShowReceipt] = useState(false);
  const createdDate = new Date(payment.createdAt);
  const [searchParams] = useSearchParams();
  const disableTxnLink = searchParams.get("appEmbed") === "true";
  const badgeKind = (status: ChargeStatuses) => {
    switch (status) {
      case ChargeStatuses.PAID:
        return "success";
      case ChargeStatuses.PENDING_NO_CARD:
      case ChargeStatuses.CHARGING:
      case ChargeStatuses.QUEUED:
        return "warning";
      case ChargeStatuses.UNPAID:
      case ChargeStatuses.FAILED:
        return "danger";
    }
  };
  return (
    <Card className={ProfileStyles.paymentMethodCard} fullWidth>
      <div>
        <Badge kind={badgeKind(payment.state)}>{intl.formatMessage(STATUSES[payment.state])}</Badge>
        <div className={ProfileStyles.paymentRow}>
          <div className={ProfileStyles.paymentCell}>
            <div className={ProfileStyles.paymentLabel}>{intl.formatMessage(LABELS.id)}</div>
            {payment.documentBundleId && payment.transactionId ? (
              disableTxnLink ? (
                <span className={ProfileStyles.link}>{payment.transactionId}</span>
              ) : (
                <Link
                  className={ProfileStyles.link}
                  to={`/bundle/records/db${payment.documentBundleId}/summary`}
                  automationId={`bundle-id-${payment.documentBundleId}`}
                >
                  {payment.transactionId}
                </Link>
              )
            ) : (
              intl.formatMessage(payment.coveredChargePayer ? LABELS.unavailable : LABELS.none)
            )}
          </div>
          <div className={ProfileStyles.paymentCell}>
            <div className={ProfileStyles.paymentLabel}>
              {format({ value: createdDate, formatStyle: "MM/dd/yyyy" })}
            </div>
            <div className={ProfileStyles.paymentTime}>
              {format({ value: createdDate, formatStyle: "p" })}
            </div>
          </div>
        </div>
        <div className={ProfileStyles.paymentRow}>
          <div className={ProfileStyles.paymentCell}>
            <div className={ProfileStyles.paymentLabel}>{intl.formatMessage(LABELS.type)}</div>
            <div className={ProfileStyles.type}>
              <Type chargeItems={payment.chargeItems} />
            </div>
          </div>
          <div className={ProfileStyles.paymentCell}>
            <div className={ProfileStyles.paymentLabel}>{intl.formatMessage(LABELS.amount)}</div>
            <Button
              buttonColor="action"
              variant="tertiary"
              className={ProfileStyles.paymentCost}
              onClick={() => setShowReceipt(!showReceipt)}
            >
              <FormattedPrice cents={payment.cost} />
            </Button>
          </div>
        </div>

        {showReceipt && (
          <MobileFullPageModal
            backLink={() => setShowReceipt(false)}
            title={intl.formatMessage(LABELS.payment)}
          >
            <PriceBreakdown charge={payment} />
          </MobileFullPageModal>
        )}
      </div>
    </Card>
  );
};

export default function Payment() {
  const isExtraSmall = useMatchScreenClass("xs");
  const intl = useIntl();
  const [showForm, setShowForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [offset, setOffset] = useState(0);
  const paymentsFetch = useFetchPersonalPayments(fetchCount, offset);
  const [payments, setPayments] = useState<null | Charge[]>();
  const [deleteCardOpen, setDeleteCardOpen] = useState(false);
  const deleteCardMutation = useMutation(DeleteCardMutation);

  useA11y().useDocumentEntitler({
    title: intl.formatMessage(useDocumentTitles().settingsProfilePersonalPayments),
  });
  useEffect(() => {
    if (!paymentsFetch.loading) {
      setPayments([...(payments || []), ...paymentsFetch.payments]);
    }
  }, [paymentsFetch.loading]);

  const { data, loading } = useQuery(CustomerProfileQuery, {
    variables: { chargeStatuses: [ChargeStatuses.QUEUED, ChargeStatuses.CHARGING] },
  });
  if (loading || !data) {
    return <LoadingIndicator />;
  }

  const user = data.viewer.user;
  const defaultCard = user?.profile?.defaultCard;

  const { expMonth, expYear } = defaultCard || {};
  const isExpired = expMonth && expYear && new Date() > new Date(expYear, expMonth);
  const hasQueuedPayments = user ? user.payments.totalCount > 0 : false;

  function deleteCard() {
    deleteCardMutation()
      .then(() => {
        showToast(intl.formatMessage(MESSAGES.paymentMethodDeletedSuccess));
        setDeleteCardOpen(false);
      })
      .catch(() => {
        setShowError(true);
      });
  }

  return (
    <>
      <div className={Styles.header}>
        <Heading textStyle="subtitle" level="h1">
          <FormattedMessage
            id="1ae5f7cb-6d28-4586-92a3-2bf55342383a"
            defaultMessage="Personal payment"
          />
        </Heading>
      </div>
      {user && (
        <div className={Styles.body}>
          <Card
            className={ProfileStyles.paymentMethodCard}
            fullWidth
            {...(!showForm &&
              !isExtraSmall && {
                footer: (
                  <PaymentButton
                    defaultCard={Boolean(defaultCard)}
                    onClick={() => setShowForm(true)}
                  />
                ),
              })}
          >
            <CardHeading>{intl.formatMessage(MESSAGES.header)}</CardHeading>
            <PaymentCopy className={ProfileStyles.paymentCopy} />
            {showForm ? (
              <div className={ProfileStyles.paymentForm}>
                <PaymentForm
                  onSuccess={() => {
                    setShowForm(false);
                    showToast(intl.formatMessage(MESSAGES.paymentSettingsSuccess));
                  }}
                  onError={() => setShowError(true)}
                  buttonLabel={intl.formatMessage(MESSAGES.savePaymentButton)}
                />
              </div>
            ) : (
              <>
                {defaultCard?.last4 && defaultCard.name && (
                  <CardPaymentMethod
                    last4={defaultCard.last4}
                    name={defaultCard.name}
                    expMonth={defaultCard.expMonth!}
                    expYear={defaultCard.expYear!}
                    deleteAction={{
                      onClick: () => setDeleteCardOpen(true),
                      disabledLabel: hasQueuedPayments
                        ? intl.formatMessage(MESSAGES.pendingPayment)
                        : undefined,
                    }}
                    className={isExpired ? ProfileStyles.cardInfoExpired : ProfileStyles.cardInfo}
                  />
                )}
                {isExpired && (
                  <Paragraph className={ProfileStyles.cardExpiredLabel}>
                    <FormattedMessage
                      id="67ca4ad2-48b4-4d42-8d6b-cff89f79779e"
                      defaultMessage="Your card has expired. Please update your payment method."
                    />
                  </Paragraph>
                )}
              </>
            )}
            {isExtraSmall && !showForm && (
              <PaymentButton
                defaultCard={Boolean(defaultCard)}
                onClick={() => setShowForm(true)}
                fullWidth
                className={!defaultCard ? ProfileStyles.button : ""}
              />
            )}
          </Card>
          {showError && <MutationErrorModal onClick={() => setShowError(false)} />}
          {deleteCardOpen && (
            <WorkflowModalWrapper
              title={
                <FormattedMessage
                  id="d534c553-a08a-421d-b45d-a57dff5ee5c2"
                  defaultMessage="Remove payment method"
                />
              }
              closeBehavior={{
                tag: "without-button",
                disableClickOutside: true,
                onClose: () => setDeleteCardOpen(false),
              }}
              buttons={[
                <Button
                  key={"cancelDelete"}
                  onClick={() => setDeleteCardOpen(false)}
                  variant="secondary"
                  buttonColor="dark"
                >
                  <FormattedMessage
                    id="88a77039-4728-4e7a-b6cf-33246029b689"
                    defaultMessage="Cancel"
                  />
                </Button>,
                <Button
                  key={"confirmDelete"}
                  onClick={deleteCard}
                  buttonColor="danger"
                  variant="primary"
                >
                  <FormattedMessage
                    id="dabc4a9d-d4bc-49de-b8a3-6efd2135e02f"
                    defaultMessage="Delete Payment Method"
                  />
                </Button>,
              ]}
            >
              <FormattedMessage
                id="3f3ad8a9-3e21-4d07-9e09-58502cbe30f6"
                defaultMessage={"Are you sure you want to remove this payment method?"}
              />
            </WorkflowModalWrapper>
          )}
          {!payments ? (
            // fetch payments loading
            <Card fullWidth className={ProfileStyles.loading}>
              <LoadingIndicator positionRelative />
            </Card>
          ) : payments.length ? (
            // if the user has payments
            <div className={ProfileStyles.paymentCards}>
              <CardHeading>{intl.formatMessage(LABELS.payments)}</CardHeading>
              {payments.map((payment) => (
                <PaymentCard key={payment.id} payment={payment} />
              ))}
              {paymentsFetch.loading ? (
                // loading more
                <Card fullWidth className={ProfileStyles.loading}>
                  <LoadingIndicator positionRelative />
                </Card>
              ) : (
                // load more button
                payments.length < paymentsFetch.totalPayments && (
                  <div className={ProfileStyles.loadMoreButton}>
                    <Button
                      buttonColor="action"
                      variant="primary"
                      onClick={() => setOffset(offset + fetchCount)}
                      {...(isExtraSmall && { fullwidth: true, large: true })}
                    >
                      Load more
                    </Button>
                  </div>
                )
              )}
            </div>
          ) : (
            // zero state
            <Card className={ProfileStyles.paymentZeroState} fullWidth>
              <CardHeading>{intl.formatMessage(LABELS.payments)}</CardHeading>
              <Paragraph className={ProfileStyles.paymentCopy}>
                {intl.formatMessage(LABELS.noPayments)}
              </Paragraph>
            </Card>
          )}
        </div>
      )}
    </>
  );
}
